import React, { useState } from "react";
import L from "leaflet";
import { MapContainer, ImageOverlay, Pane, Rectangle } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Colors } from "@blueprintjs/core";

import shelf_sizes_original from "./data/shelf_sizes.json";
import bookDetections from "./data/detections.json";

L.Map.mergeOptions({
	// @section Mousewheel options
	// @option smoothWheelZoom: Boolean|String = true
	// Whether the map can be zoomed by using the mouse wheel. If passed `'center'`,
	// it will zoom to the center of the view regardless of where the mouse was.
	smoothWheelZoom: true,

	// @option smoothWheelZoom: number = 1
	// setting zoom speed
	smoothSensitivity: 1,
});

(L.Map as any).SmoothWheelZoom = L.Handler.extend({
	addHooks: function () {
		L.DomEvent.on(this._map._container, "wheel", this._onWheelScroll, this);
	},

	removeHooks: function () {
		L.DomEvent.off(this._map._container, "wheel", this._onWheelScroll, this);
	},

	_onWheelScroll: function (e: any) {
		if (!this._isWheeling) {
			this._onWheelStart(e);
		}
		this._onWheeling(e);
	},

	_onWheelStart: function (e: any) {
		var map = this._map;
		this._isWheeling = true;
		this._wheelMousePosition = map.mouseEventToContainerPoint(e);
		this._centerPoint = map.getSize()._divideBy(2);
		this._startLatLng = map.containerPointToLatLng(this._centerPoint);
		this._wheelStartLatLng = map.containerPointToLatLng(
			this._wheelMousePosition
		);
		this._startZoom = map.getZoom();
		this._moved = false;
		this._zooming = true;

		map._stop();
		if (map._panAnim) map._panAnim.stop();

		this._goalZoom = map.getZoom();
		this._prevCenter = map.getCenter();
		this._prevZoom = map.getZoom();

		this._zoomAnimationId = requestAnimationFrame(
			this._updateWheelZoom.bind(this)
		);
	},

	_onWheeling: function (e: any) {
		var map = this._map;

		this._goalZoom =
			this._goalZoom +
			L.DomEvent.getWheelDelta(e) * 0.003 * map.options.smoothSensitivity;
		if (
			this._goalZoom < map.getMinZoom() ||
			this._goalZoom > map.getMaxZoom()
		) {
			this._goalZoom = map._limitZoom(this._goalZoom);
		}
		this._wheelMousePosition = this._map.mouseEventToContainerPoint(e);

		clearTimeout(this._timeoutId);
		this._timeoutId = setTimeout(this._onWheelEnd.bind(this), 200);

		L.DomEvent.preventDefault(e);
		L.DomEvent.stopPropagation(e);
	},

	_onWheelEnd: function (e: any) {
		this._isWheeling = false;
		cancelAnimationFrame(this._zoomAnimationId);
		this._map._moveEnd(true);
	},

	_updateWheelZoom: function () {
		var map = this._map;

		if (
			!map.getCenter().equals(this._prevCenter) ||
			map.getZoom() !== this._prevZoom
		)
			return;

		this._zoom = map.getZoom() + (this._goalZoom - map.getZoom()) * 0.3;
		this._zoom = Math.floor(this._zoom * 100) / 100;

		var delta = this._wheelMousePosition.subtract(this._centerPoint);
		if (delta.x === 0 && delta.y === 0) return;

		if (map.options.smoothWheelZoom === "center") {
			this._center = this._startLatLng;
		} else {
			this._center = map.unproject(
				map.project(this._wheelStartLatLng, this._zoom).subtract(delta),
				this._zoom
			);
		}

		if (!this._moved) {
			map._moveStart(true, false);
			this._moved = true;
		}

		map._move(this._center, this._zoom);
		this._prevCenter = map.getCenter();
		this._prevZoom = map.getZoom();

		this._zoomAnimationId = requestAnimationFrame(
			this._updateWheelZoom.bind(this)
		);
	},
});

L.Map.addInitHook(
	"addHandler",
	"smoothWheelZoom",
	(L.Map as any).SmoothWheelZoom
);

const gap = 20;

const shelf_sizes = shelf_sizes_original.map((shelf_size) =>
	shelf_size.map((p) => p / 5)
);
const bounds: [number, number][] = [
	[2800 / 5, 0],
	[0, shelf_sizes[0][0] + shelf_sizes[4][0] + shelf_sizes[8][0] + 2 * gap],
];
const bounds1: [number, number][] = [
	[2800 / 5, 0],
	[0, shelf_sizes[0][0]],
];
const bounds2: [number, number][] = [
	[2800 / 5, shelf_sizes[0][0] + gap],
	[0, shelf_sizes[0][0] + shelf_sizes[4][0] + gap],
];
const bounds3: [number, number][] = [
	[2800 / 5, shelf_sizes[0][0] + shelf_sizes[4][0] + 2 * gap],
	[0, shelf_sizes[0][0] + shelf_sizes[4][0] + shelf_sizes[8][0] + 2 * gap],
];

const offsetYs = (shelf: number) => [
	shelf_sizes[3 + 4 * shelf][1] +
		shelf_sizes[2 + 4 * shelf][1] +
		shelf_sizes[1 + 4 * shelf][1],
	shelf_sizes[3 + 4 * shelf][1] + shelf_sizes[2 + 4 * shelf][1],
	shelf_sizes[3 + 4 * shelf][1],
	0,
];
const offsetXs = [
	0,
	shelf_sizes[0][0] + gap,
	shelf_sizes[0][0] + shelf_sizes[4][0] + 2 * gap,
];

const bookDetectionsFlattened = bookDetections
	.map((detections, i) =>
		detections.map((detection) => {
			const bounds = detection.coords.map((p) => [
				offsetYs(Math.floor(i / 4))[i % 4] + p[1] * shelf_sizes[i][1],
				offsetXs[Math.floor(i / 4)] + p[0] * shelf_sizes[i][0],
			]) as [number, number][];
			return { bounds, shelfIndex: i, id: detection.id };
		})
	)
	.flat(1);

interface Props {
	dark: boolean;
	books: { id: number; read: 0 | 1 | 2 }[];
}

const PublicShelfDisplay: React.FC<Props> = ({ dark, books }) => {
	const [, setMap] = useState<any>(undefined);
	const [overlay, setOverlay] = useState(true);

	const booksWithDetections = books.map((book) => {
		const detection = bookDetectionsFlattened.find((d) => d.id === book.id);

		return { ...detection, ...book };
	});

	return (
		<div style={{ position: "relative" }}>
			<button
				style={{ top: 10, left: 100, position: "absolute", zIndex: 999 }}
				onClick={() => {
					setOverlay(!overlay);
				}}
			>
				{overlay ? "off" : "on"}
			</button>
			<MapContainer
				crs={L.CRS.Simple}
				zoomSnap={0.1}
				style={{
					top: 0,
					left: 0,
					width: "800px",
					height: "670px",
					maxWidth: window.innerWidth - 20,
					backgroundColor: dark ? Colors.DARK_GRAY3 : Colors.LIGHT_GRAY5,
				}}
				bounds={bounds}
				whenCreated={(m) => {
					m.createPane("books");
					m.setMaxBounds(bounds);
					m.fitBounds(bounds, { padding: [0, 0] });
					//m.setMinZoom(m.getZoom());

					setMap(m);
				}}
				scrollWheelZoom={false} // disable original zoom function
				smoothWheelZoom={true} // enable smooth zoom
				smoothSensitivity={1} // zoom speed. default is 1
				maxZoom={4}
			>
				<ImageOverlay url={"/shelf1.jpg"} bounds={bounds1} />
				<ImageOverlay url={"/shelf2.jpg"} bounds={bounds2} />
				<ImageOverlay url={"/shelf3.jpg"} bounds={bounds3} />
				<Pane name="books">
					{booksWithDetections.map((book) => {
						return book.bounds ? (
							<Rectangle
								key={book.id}
								bounds={book.bounds}
								pathOptions={{
									color:
										book.read === 0
											? "red"
											: book.read === 1
											? "orange"
											: "green",
									fillOpacity: overlay ? 0.35 : 0,
									weight: overlay ? 2 : 0,
								}}
							/>
						) : undefined;
					})}
				</Pane>
			</MapContainer>
		</div>
	);
};
export default PublicShelfDisplay;
