import { Book } from "./Interfaces";

export const baseURL = "https://shelf.li/api";
//export const baseURL = "http://localhost:2000";

export const emptyBook = {
	isbn: "",
	authors_id: 0,
	name: "",
	currentpage: 0,
	totalpage: 0,
	summary: "no description",
	imageurl: "no image",
} as Book;

export const genres = [
	{ id: 0, name: "Classic" },
	{ id: 1, name: "Thriller" },
	{ id: 2, name: "Mystery" },
	{ id: 3, name: "Horror" },
	{ id: 4, name: "Historical" },
	{ id: 5, name: "Science Fiction" },
	{ id: 6, name: "Fantasy" },
	{ id: 7, name: "History" },
	{ id: 8, name: "Biography" },
	{ id: 9, name: "Biology" },
	{ id: 10, name: "Chemistry" },
	{ id: 11, name: "Physics" },
	{ id: 12, name: "Mathematics" },
	{ id: 13, name: "Current Affairs" },
];
