import { css } from "glamor";
import * as React from "react";
import { Colors, H3 } from "@blueprintjs/core";
import { Link } from "react-router-dom";

const styles = {
	container: css({
		width: "100%",
		maxWidth: "800px",
		borderRadius: "5px 5px 0 0",
		backgroundColor: Colors.RED3,
		position: "fixed",
		bottom: 0,
		zIndex: 999,
	}),
	text: css({
		color: Colors.LIGHT_GRAY5 + " !important",
		textAlign: "center",
		marginTop: "5px",
	}),
};

const DemoBanner: React.FC = () => {
	return (
		<div {...styles.container}>
			<H3 {...styles.text}>You are logged into the demo account. Changes will not be saved!</H3>
			<H3 {...styles.text}>
				Register your account{" "}
				<Link to="/register" onClick={() => localStorage.setItem("shelfli_token", "")}>
					here
				</Link>
			</H3>
		</div>
	);
};

export default DemoBanner;
