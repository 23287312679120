/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import { NonIdealState } from "@blueprintjs/core";

import books from "./data/books.json";

import PublicShelfDisplay from "PublicShelfDisplay";

interface Props {
	dark: boolean;
}

export default class PublicBookTab extends React.Component<Props, {}> {
	render() {
		return books.length === 0 ? (
			<NonIdealState title="It's a bit empty here, add books by clicking on the barcode on the Home Page..." />
		) : (
			<div>
				<PublicShelfDisplay
					dark={this.props.dark}
					books={books as { id: number; read: 1 | 2 | 0 }[]}
				/>
			</div>
		);
	}
}
