import { Colors } from "@blueprintjs/core";
import { css } from "glamor";
import * as React from "react";
import Analytics from "react-router-ga";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Header from "./Header";
import AuthWall from "./AuthWall";
import InfoPage from "./InfoPage";
import { hasValidToken } from "./api";
import PublicBookTab from "PublicBookTab";
import DemoBanner from "DemoBanner";

const UserPage = React.lazy(() => import("./UserPage"));

const styles = {
	App: (dark: boolean) =>
		css({
			backgroundColor: dark ? Colors.DARK_GRAY3 : Colors.LIGHT_GRAY5,
			height: "100%",
			minHeight: "100vh",
			color: dark
				? Colors.LIGHT_GRAY5 + " !important"
				: Colors.DARK_GRAY3 + " !important",
		}),
	container: css({
		width: "100%",
		maxWidth: "800px",
		margin: "auto",
		height: "100%",
		minHeight: "100vh",
		position: "relative",
		"@media (max-width: 820px)": {
			overflowX: "hidden",
		},
	}),
	contentWrap: css({ paddingBottom: "100px", marginBottom: "0px" }),
};

const App: React.FC = () => {
	const [dark, setDark] = React.useState(
		(localStorage.getItem("shelfli_theme") === "dark" &&
			localStorage.getItem("shelfli_theme") !== "light") ||
			(window.matchMedia &&
				window.matchMedia("(prefers-color-scheme: dark)").matches)
	);

	const toggleDark = () => {
		localStorage.setItem("shelfli_theme", dark ? "light" : "dark");
		setDark(!dark);
	};
	const hasValidTokenValue = hasValidToken();
	return (
		<div className={dark ? "bp3-dark" : ""} {...styles.App(dark)}>
			<div {...styles.container}>
				<div {...styles.contentWrap}>
					<BrowserRouter>
						<div>
							<Analytics id="UA-167399767-1">
								<Switch>
									<Redirect
										exact={true}
										from="/"
										to={hasValidTokenValue ? "/shelf" : "/info"}
									/>
									<Route
										path="/shelf"
										render={(props) => (
											<div>
												<Header
													loggedIn={true}
													dark={dark}
													onChange={toggleDark}
													{...props}
												/>
												<React.Suspense fallback={<div />}>
													<AuthWall>
														<UserPage {...props} dark={dark} />
													</AuthWall>
												</React.Suspense>
											</div>
										)}
									/>
									<Route
										path="/publicShelf"
										render={(props) => (
											<div>
												<Header
													loggedIn={false}
													dark={dark}
													onChange={toggleDark}
													{...props}
												/>
												<PublicBookTab {...props} dark={dark} />
												<DemoBanner />
											</div>
										)}
									/>
									<Route
										path="/"
										render={(props) => (
											<div>
												<Header
													loggedIn={false}
													dark={dark}
													onChange={toggleDark}
													{...props}
												/>
												<InfoPage {...props} dark={dark} />
											</div>
										)}
									/>
								</Switch>
							</Analytics>
						</div>
					</BrowserRouter>
				</div>
			</div>
		</div>
	);
};

export default App;
