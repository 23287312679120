import { Colors, Switch } from "@blueprintjs/core";
import { css } from "glamor";
import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import {
	Collapse,
	Nav,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
} from "reactstrap";

const styles = {
	header: css({
		backgroundColor: Colors.DARK_GRAY5,
		color: Colors.LIGHT_GRAY5 + " !important",
		padding: "10px",
		margin: "0 0 20px 0",
		textAlign: "left",
		borderRadius: "0 0 3px 3px",
	}),

	home: css({
		fontSize: "34px !important",
		color: Colors.LIGHT_GRAY5,
		":hover": { color: Colors.LIGHT_GRAY4, textDecoration: "none" },
	}),
	link: css({
		fontSize: "18px !important",
		color: Colors.LIGHT_GRAY5,
		marginTop: "16px",
		marginLeft: "20px",
		":hover": {
			borderBottom: "2px solid",
			color: Colors.LIGHT_GRAY5 + " !important",
			textDecoration: "none !important",
		},
	}),
	makeBottom: css({ marginTop: "10px" }),
	switch: css({
		marginTop: "2px",
		marginLeft: "40px",
		"@media (max-width: 780px)": {
			marginLeft: "20px",
		},
	}),
};

const links = {
	loggedIn: {
		left: [
			{ to: "/shelf", label: "Home" },
			{ to: "/shelf/books", label: "Books" },
			{ to: "/shelf/authors", label: "Authors" },
			{ to: "/shelf/stats", label: "Stats" },
			{ to: "/shelf/spines", label: "Spines" },
		],
		right: [
			{ to: "/shelf/user", label: "User" },
			{
				to: "/info",
				label: "Logout",
				extraOnClick: () => localStorage.setItem("shelfli_token", ""),
			},
		],
	},
	loggedOut: {
		left: [{ to: "/info", label: "Info" }],
		right: [{ to: "/shelf", label: "Login", extraOnClick: () => undefined }],
	},
};
interface Props {
	dark: boolean;
	onChange: () => void;
	loggedIn: boolean;
	location: { pathname: string };
}
const Header: React.FC<Props> = ({ dark, onChange, loggedIn, location }) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const makeLink = (to: string, label: string, extraOnClick: () => void) => {
		return (
			<NavItem key={label}>
				<NavLink
					{...styles.link}
					to={to}
					activeStyle={{
						borderBottom: "2px solid",
					}}
					exact={true}
					onClick={() => {
						setIsOpen(false);
						extraOnClick();
					}}
				>
					{label}
				</NavLink>
			</NavItem>
		);
	};
	const correctLinks = loggedIn ? links.loggedIn : links.loggedOut;
	return (
		<div {...styles.header}>
			<Navbar dark={true} expand="md">
				<NavbarBrand>
					<Link
						to={loggedIn ? "/shelf" : "/"}
						{...styles.home}
						onClick={() => {
							setIsOpen(false);
						}}
					>
						Shelf.li
					</Link>
				</NavbarBrand>
				<NavbarToggler
					onClick={() => {
						setIsOpen(!isOpen);
					}}
				/>
				<Collapse isOpen={isOpen} navbar={true}>
					<Nav className="mr-auto" navbar={true} {...styles.makeBottom}>
						{correctLinks.left.map((link) =>
							makeLink(link.to, link.label, () => null)
						)}
						<div {...styles.switch}>
							<Switch
								checked={dark}
								onChange={onChange}
								large={true}
								label="Dark"
							/>
						</div>
					</Nav>
					<Nav>
						{correctLinks.right.map((link) =>
							makeLink(
								link.to,
								link.label,
								link.extraOnClick ? link.extraOnClick : () => null
							)
						)}
					</Nav>
				</Collapse>
			</Navbar>
		</div>
	);
};
export default Header;
