import * as jwt from "jsonwebtoken";
import { baseURL } from "./constants";
import { Book, Challenge } from "./Interfaces";

export const hasValidToken = () => {
	const token = localStorage.getItem("shelfli_token");
	if (!token) {
		return false;
	}
	const decodedToken = jwt.decode(token, { complete: true }) as any;
	const dateNow = new Date();

	if (decodedToken.payload.exp < dateNow.getTime() / 1000) {
		return false;
	}
	return true;
};
export const getUserID = () => {
	const token = localStorage.getItem("shelfli_token");
	if (!token) {
		return 0;
	}
	const decodedToken = jwt.decode(token, { complete: true }) as any;

	console.log(decodedToken);
	return decodedToken.payload.userId;
};
export const isDemoAccount = () => {
	const token = localStorage.getItem("shelfli_token");
	if (!token) {
		return false;
	}
	const decodedToken = jwt.decode(token, { complete: true }) as any;

	return decodedToken.payload.demo;
};
export const setToken = (newToken: string) => {
	localStorage.setItem("shelfli_token", newToken);
	return true;
};
export const timeUntilRefresh = () => {
	const token = localStorage.getItem("shelfli_token");
	if (!token) {
		return 0;
	}
	const decodedToken = jwt.decode(token, { complete: true }) as any;
	const timeOutTime = decodedToken.payload.exp;
	const issueTime = decodedToken.payload.iat;
	const timeNow = Math.floor(new Date().getTime() / 1000);

	const refreshTime = (timeOutTime + issueTime) / 2;
	return Math.max(0, refreshTime - timeNow) * 1000;
};

const getData = async (route: string) => {
	const res = await fetch(baseURL + route, {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("shelfli_token"),
		},
	});
	const data = await res.json();
	return data;
};

const sendData = async (
	route: string,
	payload: object,
	method: string = "post"
) => {
	try {
		const res = await fetch(baseURL + route, {
			method,
			headers: {
				Authorization: "Bearer " + localStorage.getItem("shelfli_token"),
				Accept: "application/json, text/plain, */*",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		});
		return await res.json();
	} catch {
		return { success: false, message: "request failed" };
	}
};

export const login = (email: string, password: string) =>
	sendData("/login", { email, password });
export const resetPassword = (password: string) =>
	sendData("/changePassword", { password });
export const register = (email: string, password: string) =>
	sendData("/register", { email, password });
export const getBooks = async () => await getData("/books");
export const getUserInfos = async () => await getData("/userInfos");
export const getHistory = async () => await getData("/history");
export const getAuthors = async () => await getData("/authors");
export const getChallenges = async () => await getData("/getChallenges");
export const fetchBookInfo = async (query: string) =>
	sendData("/fetchBookInfo", { query });
export const createAuthorIfNecessary = async (
	author_first: string,
	author_last: string
) => sendData("/createAuthorIfNecessary", { author_first, author_last });
export const addBook = async (
	isbn: string,
	name: string,
	authors_id: number,
	currentpage: number,
	totalpage: number,
	summary: string,
	imageurl: string
) =>
	sendData("/addBook", {
		isbn,
		name,
		authors_id,
		currentpage,
		totalpage,
		summary,
		imageurl,
	});
export const addBooks = async (books: Book[]) =>
	sendData("/addBooks", { books });
export const updateCurrentPage = async (
	id: number,
	currentpage: number,
	totalpage: number
) => sendData("/updateCurrentPage", { id, currentpage, totalpage });
export const updateFinishedDate = async (id: number, finishedDate: string) =>
	sendData("/updateFinishedDate", { id, finishedDate });
export const setTitle = async (book_id: number, title: string) =>
	sendData("/setTitle", { book_id, title });
export const setChallenge = async (challenge: Challenge) =>
	sendData("/setChallenge", {
		bookCount: challenge.bookcount,
		pageCount: challenge.pagecount,
		showBookCount: challenge.showbookcount,
		showPageCount: challenge.showpagecount,
	});
export const setImageURL = async (book_id: number, imageURL: string) =>
	sendData("/setImageURL", { book_id, imageURL });
export const refresh = async () => {
	const res = await fetch(baseURL + "/refreshToken", {
		method: "POST",
		headers: {
			Authorization: "Bearer " + localStorage.getItem("shelfli_token"),
			Accept: "application/json, text/plain, */*",
			"Content-Type": "application/json",
		},
	});
	if (res.status !== 200) {
		throw new Error("failed to refresh token");
	}
	const resJson = await res.json();
	return resJson.token;
};

export const deleteBook = async (book_id: number) =>
	sendData("/deleteBook", { book_id });

export const getGoodreadsShelves = async (goodreadsId: number) =>
	sendData("/getGoodreadsShelves", { goodreadsId });
export const getGoodreadsBooks = async (goodreadsId: number, shelf: string) =>
	sendData("/getGoodreadsBooks", { goodreadsId, shelf });

export const setSpinePic = async (pic: string | undefined, book_id: number) => {
	return sendData("/setSpinePic/" + book_id, { pic }, "post");
};

export const getSpinePic = async (book_id: number) =>
	(await getData("/getSpinePic/" + book_id)).spine_pic;

export const setGenre = async (book_id: number, genre: number) =>
	sendData("/setGenre", { id: book_id, genre });
