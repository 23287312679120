/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import {
	hasValidToken,
	login,
	refresh,
	setToken as _setToken,
	timeUntilRefresh,
	isDemoAccount,
} from "./api";
import Login from "./Login";
import DemoBanner from "./DemoBanner";

interface Props {
	children: React.ReactElement;
}

function useSetToken() {
	const [, setDummy] = useState({});
	return (token: string) => {
		_setToken(token);
		setDummy({});
	};
}

const AuthWall: React.FC<Props> = ({ children }) => {
	const setToken = useSetToken();

	const refreshHandle = useRef<NodeJS.Timeout>();
	const refreshToken = async () => {
		try {
			const res = await refresh();
			setToken(res);
		} catch (e) {
			console.error(e);
			setToken("");
		}
	};

	useEffect(() => {
		if (!hasValidToken()) {
			setToken("");
		}
	}, [hasValidToken()]);

	useEffect(() => {
		if (hasValidToken()) {
			refreshToken();
		}
	}, []);

	useEffect(() => {
		if (refreshHandle.current) {
			clearTimeout(refreshHandle.current);
		}
		if (hasValidToken()) {
			refreshHandle.current = setTimeout(refreshToken, timeUntilRefresh());
		}
	});

	if (!hasValidToken()) {
		return (
			<Login
				onSuccessfulLogin={(res: any) => {
					setToken(res.token);
				}}
				login={login}
				title="Shelf.li"
			/>
		);
	}
	return (
		<div>
			{isDemoAccount() ? <DemoBanner /> : undefined}
			{children}
		</div>
	);
};

export default AuthWall;
