import { Button, Card, H3, InputGroup, Intent, FormGroup } from "@blueprintjs/core";
import { css } from "glamor";
import * as React from "react";
import { useEffect, useState } from "react";
import { register, setToken } from "./api";
import { Redirect } from "react-router-dom";

const styles = {
	container: css({ width: "100%" }),
	card: css({ padding: "20px", width: "300px", margin: "0 auto", textAlign: "left" }),
	button: css({ height: "30px" }),
};

const Register: React.FC = () => {
	const [email, setEmail] = useState("");
	const [emailConstraints, setEmailConstraints] = useState(true);

	const [password, setPassword] = useState("");
	const [passwordConstraints, setPasswordConstraints] = useState(true);

	const [repeatPassword, setRepeatPassword] = useState("");
	const [repeatPasswordMatches, setRepeatPasswordMatches] = useState(true);

	//0:default, 1: email registered, 2: request failed, 3: success
	const [registerResponse, setRegisterResponse] = useState(0);

	useEffect(() => {
		setEmailConstraints((email.includes("@") && email.includes(".")) || email === "");
	}, [email]);

	useEffect(() => {
		setPasswordConstraints(password.length >= 8 || password.length === 0);
	}, [password]);

	useEffect(() => {
		setRepeatPasswordMatches(password === repeatPassword);
	}, [password, repeatPassword]);

	useEffect(() => {
		setRegisterResponse(0);
	}, [password, email, repeatPassword]);

	const tryRegister = async () => {
		if (!emailConstraints || !passwordConstraints || !repeatPasswordMatches) {
			return;
		}
		if (!email) {
			setEmailConstraints(false);
			return;
		}
		if (!password) {
			setPasswordConstraints(false);
			return;
		}
		const res = await register(email, password);
		if (res.success) {
			if (setToken(res.token)) {
				setRegisterResponse(3);
			}
		} else {
			if (res.message === "email registered already") {
				setRegisterResponse(1);
			} else {
				setRegisterResponse(2);
			}
		}
	};
	return (
		<div {...styles.container}>
			<Card {...styles.card}>
				<H3>Register for Shelf.li</H3>
				<FormGroup
					label="Email"
					labelFor="text-input"
					helperText={
						!emailConstraints
							? "please enter a valid email adress"
							: registerResponse === 1
							? "email already registered"
							: ""
					}
					intent={Intent.DANGER}
				>
					<InputGroup
						placeholder="email"
						value={email}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
						intent={registerResponse === 1 ? Intent.DANGER : Intent.NONE}
						fill={true}
						onKeyDown={(e) => (e.key === "Enter" ? tryRegister() : undefined)}
					/>
				</FormGroup>
				<FormGroup
					label="Password"
					labelFor="text-input"
					helperText=">=8 characters"
					intent={passwordConstraints ? Intent.NONE : Intent.DANGER}
				>
					<InputGroup
						placeholder="password"
						value={password}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
						intent={passwordConstraints ? Intent.NONE : Intent.DANGER}
						type="password"
						fill={true}
						onKeyDown={(e) => (e.key === "Enter" ? tryRegister() : undefined)}
					/>
				</FormGroup>
				<FormGroup
					label="Repeat Password"
					labelFor="text-input"
					helperText={repeatPasswordMatches ? "" : "the 2 passwords don't match..."}
					intent={repeatPasswordMatches ? Intent.NONE : Intent.DANGER}
				>
					<InputGroup
						placeholder="repeat password"
						value={repeatPassword}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRepeatPassword(e.target.value)}
						intent={repeatPasswordMatches ? Intent.NONE : Intent.DANGER}
						type="password"
						fill={true}
						onKeyDown={(e) => (e.key === "Enter" ? tryRegister() : undefined)}
					/>
				</FormGroup>
				<FormGroup
					helperText={
						registerResponse === 2 ? "Login failed, are you connected to the internet?" : ""
					}
					intent={Intent.DANGER}
				>
					<Button outlined={true} onClick={tryRegister} {...styles.button}>
						Register
					</Button>
				</FormGroup>
			</Card>
			{registerResponse === 3 ? <Redirect to="/shelf" /> : null}
		</div>
	);
};

export default Register;
