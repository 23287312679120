import { Button, Card, H3, InputGroup, Intent, FormGroup } from "@blueprintjs/core";
import { css } from "glamor";
import * as React from "react";
import { useEffect, useState } from "react";

const styles = {
	container: css({ width: "100%" }),
	card: css({ padding: "20px", width: "300px", margin: "0 auto", textAlign: "left" }),
	button: css({ height: "30px" }),
};

interface Props {
	onSuccessfulLogin: (res: any) => void;
	login: (username: string, password: string) => any;
	title: string;
}
const Login: React.FC<Props> = ({ onSuccessfulLogin, login, title }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [wrongPassword, setWrongPassword] = useState(false);
	const [requestFailed, setRequestFailed] = useState(false);

	useEffect(() => {
		setWrongPassword(false);
	}, [password, email]);

	const tryLogin = async () => {
		const res = await login(email, password);
		if (res.success) {
			setWrongPassword(false);
			setRequestFailed(false);
			onSuccessfulLogin(res);
		} else {
			if (res.message === "request failed") {
				setRequestFailed(true);
				setWrongPassword(false);
			} else {
				setRequestFailed(false);
				setWrongPassword(true);
			}
		}
	};
	return (
		<div {...styles.container}>
			<Card {...styles.card}>
				<H3>Login to {title}</H3>
				<FormGroup label="Email" labelFor="text-input">
					<InputGroup
						placeholder="email"
						value={email}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
						intent={wrongPassword ? Intent.DANGER : Intent.NONE}
						fill={true}
						onKeyDown={(e) => (e.key === "Enter" ? tryLogin() : undefined)}
					/>
				</FormGroup>
				<FormGroup
					label="Password"
					labelFor="text-input"
					helperText={wrongPassword ? "username and/or password incorrect" : ""}
					intent={Intent.DANGER}
				>
					<InputGroup
						placeholder="password"
						value={password}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
						intent={wrongPassword ? Intent.DANGER : Intent.NONE}
						type="password"
						fill={true}
						onKeyDown={(e) => (e.key === "Enter" ? tryLogin() : undefined)}
					/>
				</FormGroup>
				<FormGroup
					helperText={requestFailed ? "Login failed, are you connected to the internet?" : ""}
					intent={Intent.DANGER}
				>
					<Button outlined={true} onClick={tryLogin} {...styles.button}>
						Login
					</Button>
				</FormGroup>
			</Card>
		</div>
	);
};

export default Login;
