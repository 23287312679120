import * as React from "react";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import shelf from "./logo.svg";
import { css } from "glamor";
import Register from "./Register";
import { NonIdealState, Button, Intent } from "@blueprintjs/core";
import { login, setToken } from "./api";

interface Props {
	match: { path: string };
	dark: boolean;
}

const styles = {
	svg: css({ width: "220px", marginTop: "0px", marginBottom: "20px" }),
	container: css({
		width: "100%",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		alignItems: "center",
	}),
	text1: css({ margin: "40px", textAlign: "center" }),
	text2: css({ marginBot: "20px", textAlign: "center" }),
	text3: css({ marginTop: "20px", textAlign: "center" }),
};
const InfoPage: React.FC<Props> = ({ match, dark }) => {
	const [redirectToShelf, setRedirectToShelf] = React.useState(false);
	return (
		<div>
			{redirectToShelf ? <Redirect to="/shelf" /> : <div />}
			<Switch>
				<Route
					exact={true}
					path="/info"
					render={(props) => (
						<div {...styles.container}>
							<div {...styles.text1}>
								<b>Shelf.li</b> - Track your reading progress!
							</div>
							<img {...styles.svg} src={shelf} alt="barcode" />
							<div {...styles.text2}>
								<Link
									to="/info"
									onClick={async () => {
										const res = await login("demo", "demo");
										if (res.success) {
											setToken(res.token);
											setRedirectToShelf(true);
										}
									}}
								>
									<Button intent={Intent.PRIMARY} large={true}>
										Try the demo!
									</Button>
								</Link>
							</div>
							<div {...styles.text3}>
								Or <Link to="/register">register</Link> your free account
							</div>
							<div {...styles.text3}>
								Import your Goodreads Shelves automatically once you are logged in!
							</div>
						</div>
					)}
				/>
				<Route exact={true} path="/register" render={(props) => <Register />} />
				<Route
					path="*"
					exact={true}
					render={(props) => (
						<div>
							<NonIdealState
								icon="help"
								title="This page does not exist!"
								description="Please go back to wherever you came from..."
							/>
						</div>
					)}
				/>
			</Switch>
		</div>
	);
};
export default InfoPage;
